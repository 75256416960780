export default {
  BUTTON_PRIMARY_CLASS: 'btn btn-primary',
  BUTTON_SECONDARY_CLASS: 'btn btn-default',

  PRODUCT_REPEATER_CLASS: 'product-items product-items-3',

  FACET_INPUT_CLASS: 'form-control',

  SELECT_YOUR_VEHICLE_BLOCK: 'Select Your Vehicle',

  SEARCH_BOX_INPUT_CLASS: 'search-text form-control',
  SEARCH_BOX_BUTTON_CLASS: 'search-submit btn btn-default btn-inverse',
};
